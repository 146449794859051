import React, { useContext } from 'react';
import './Login.css';
import Navbar from '../Shared/Navbar/Navbar';
import { initializeApp } from 'firebase/app';
import firebaseConfig from './firebase.config';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, doc, setDoc, serverTimestamp } from "firebase/firestore"; // Import Firestore and necessary functions
import googleLogo from '../../images/google.png';
import { UserContext } from '../../App';
import { useHistory, useLocation } from 'react-router';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore


const Login = () => {
    const {userInfo} = useContext(UserContext);
    const [loggedInUser, setLoggedInUser] = userInfo;

    const history = useHistory();
    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/" } };


    const provider = new GoogleAuthProvider();

    const handleGoogleSignIn = () =>{
        const auth = getAuth();
        signInWithPopup(auth, provider)
        .then(async (result) => { // Mark this function as async
            const user = result.user;
            // Update login date in Firestore
            const userRef = doc(db, "users", user.uid); // Reference to the user document in Firestore
            await setDoc(userRef, {
                lastLogin: serverTimestamp() // Use serverTimestamp for accurate time
            }, { merge: true }); // Merge true to update the document without overwriting existing data

            setLoggedInUser(user);
            history.replace(from);
       
        }).catch((error) => {  
            // Handle errors here
        });
    }




    return (
        <section className="login-section">
           <Navbar />
            <div  className="signin">
                <h2>Sign In</h2>
                <div className="signin-btn" onClick={handleGoogleSignIn}>
                    <img src={googleLogo} alt="" />
                    <p>Continue with Google</p>
                </div>
            </div>
           

            
        </section>
    );
};

export default Login;