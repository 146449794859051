import React from 'react';
import Footer from '../Shared/Footer/Footer';
import About from '../Home/About/About';
import Header from '../Home/Header/Header';
import NavbarForPages from '../Shared/NavbarForPages/NavbarForPages';

const AboutPage = () => {
    return (
        < div id="home">
            <NavbarForPages />
            <div style={{ marginTop: '100px' }}></div>
            <About />
            <Footer />
        </div>
    );
};

export default AboutPage;