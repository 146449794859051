import React from 'react';
import { useEffect, useState } from 'react';
import AppointmentList from '../AppointmentList/AppointmentList';
import './AllAppointments.css'
// firestore import
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';

const app = initializeApp(firebaseConfig);

const AllAppointments = () => {

    // const fakeData = [
    //     {
    //         name: 'Customer Name',
    //         email: 'test@gmail.com',
    //         address: 'Dhaka, Bangladesh',
    //         phone: '017XXXXXXXX',
    //         service: 'Service Name',
    //         tutor: 'Barber Name',
    //         status: 'pending',
    //         price: 100,
    //         date: '12/12/2021',
    //         time: '12:00 PM',
    //         _id: '123456',
    //         paymentInfo: "123456"
    //     },
    // ];


    const [allAppointments, setAllAppointments] = useState([]);


    useEffect(() => {
        // // fake data for allAppointments
        // setAllAppointments(fakeData);

        // firestore data for allAppointments
        const db = getFirestore(app);
        const appointmentsCollection = collection(db, '/appointments/allAppointments/apt_list');
        getDocs(appointmentsCollection)
            .then((querySnapshot) => {
                const documents = querySnapshot.docs.map(doc => doc.data());
                // sort the appointments by date ie createAt
                // formate 2024-07-10T20:44:19.723Z

                documents.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });

                setAllAppointments(documents);
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });


    }, [])



    return (
        <div className="appointment-container">
            <table className=" table table-striped">
                <thead>
                    <tr className='bg-dark'>
                        <th scope="col">#</th>
                        <th scope="col">Customer Details</th>
                        <th scope="col">Service Details</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allAppointments.map((appointment, index) => <AppointmentList appointment={appointment}
                            key={index} index={index}

                        />)
                    }

                </tbody>
            </table>





        </div>
    );
};

export default AllAppointments;