import React, { useContext,  useState } from 'react';
import './UserDashboard.css';
import AddAppointment from '../AddAppointment/AddAppointment';
import Review from '../Review/Review';
import logo from '../../../images/MainLogo.png';
import { useHistory } from 'react-router';
import MyAppointments from '../MyAppointments/MyAppointments';
import { UserContext } from '../../../App';
import Navbar from '../../Shared/Navbar/Navbar';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useEffect } from 'react';

const UserDashboard = () => {
    const {userInfo} = useContext(UserContext);
    const [loggedInUser, setLoggedInUser] = userInfo;
  const history = useHistory();  
    const [activeNav, setActiveNav] = useState("add bookings");
    // New state variable to track loading state of auth check
    const [checkingAuth, setCheckingAuth] = useState(true);


    const handleActiveNav =(e) =>{
        const innerText = e.target.innerText;
        setActiveNav(innerText.toLowerCase());
    }

    const handleGoHome =()=>{
        history.push('/');
    }
    
    // Log Out user from firebase
    const handleLogOut =()=>{
        // setLoggedInUser({});
        // sessionStorage.removeItem('token');
        const app = initializeApp(firebaseConfig);
        const auth = getAuth();
        auth.signOut().then(() => {
            setLoggedInUser({});
            sessionStorage.removeItem('token');
            history.push('/');
        }).catch((error) => {
            console.log(error);
        });

    }

    
    useEffect(() => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (!user) {
                history.push('/login');
            } else {
                // If user is found, set checkingAuth to false
                setCheckingAuth(false);
            }
        });
    }, [history]);

    // If still checking auth, you can decide to render nothing or a loading indicator
    if (checkingAuth) {
        return <div>Loading...</div>;
    }

    

    return (
        <div>
        
            <div className="container  my-5  p-5 rounded border border-1 user-dashboard ">
                <div className="d-flex align-items-center user-info py-2">
                    <img src={loggedInUser.photoURL} alt="user-pic" />
                    <h3>Hello, <b>{loggedInUser.displayName} </b></h3>
                    
                </div>
                <hr />


                <div className="row">
                    <div className="col-md-3 text-center ">
                        <div className="user-side-nav">
                            <ul onClick={handleActiveNav}>
                                <li  className={activeNav ==="add bookings" ? "active" : ""}><i className="far fa-calendar-alt"></i>Add Bookings</li>
                                <li  className={activeNav ==="my bookings" ? "active" : ""}><i className="far fa-list-alt"></i>My Bookings</li>
                                <li  className={activeNav ==="review" ? "active" : ""}><i className="far fa-thumbs-up"></i>Review</li>
                            </ul>

                            <div className="user-action">
                                <button onClick={handleLogOut}>Log Out</button>
                                <button onClick={handleGoHome}>Go HOME</button>
                            </div>
                            <img className='Logo' style={{width:"200px", marginTop: "20px"}} src={logo} alt="" />

                        </div>

                    
                    
                    </div>
                    <div className="col-md-9">
                    <div className="Content">
                            {
                                activeNav === 'add bookings' && <AddAppointment />
                            }
                            {
                                activeNav === 'my bookings' && <MyAppointments />
                            }
                            {
                                activeNav === 'review' && <Review />
                            }
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default UserDashboard;