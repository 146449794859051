import React, { useState } from 'react';
import './ServiceUpdateModal.css';
import { useForm } from "react-hook-form";
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';
import { getFirestore, doc, setDoc, serverTimestamp, collection, query, where, getDocs } from "firebase/firestore"; // Import Firestore and necessary functions
import { getFunctions, httpsCallable } from "firebase/functions";
import { reload } from 'firebase/auth';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';



const ServiceUpdateModal = ({ loadService, setLoadModal }) => {
  const { id, service, price, img } = loadService;
  const [updateImg, setUpdateIma] = useState(img);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const history = useHistory();


  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => {
    console.log(data);
    const updateServiceInfo = {
      id : id,
      service: data.service,
      price: data.price,
      img: updateImg,
    }
  
    const db = getFirestore();
    // Correctly using query to find documents
    const q = query(collection(db, "data/agam_data/services"), where("id", "==", id));
    getDocs(q).then((querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          // updating document
          setDoc(doc.ref, {
            ...updateServiceInfo,
            createdAt: serverTimestamp(),
          }).then(() => {
            toast.success("Service updated successfully!", { position: "top-right" });
            setLoadModal(false);  

          }).catch(error => console.error("Error updating document: ", error));
        });
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting documents:", error);
    });

  }


  const handleUpdateImage = async event => {
    setLoadingSpinner(true);
    const imageData = new FormData();
    imageData.set('key', '5db3a5ec8d1a5a41ae46727cd85094e2');
    imageData.append('image', event.target.files[0]);

    const response = await fetch('https://api.imgbb.com/1/upload', {
      method: 'POST',
      body: imageData
    })
    const data = await response.json();
    setUpdateIma(data.data.display_url);


  }



  return (
    <div className="edit-container">
      <div className="close-btn"><i onClick={() => setLoadModal(false)} class="fas fa-times-circle"></i></div>
      <h3>EDIT SERVICE</h3><br />
      <div className="row">
        <div className="col-md-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="service">Service Name</label>
            <input defaultValue={service} {...register("service")} />

            <label htmlFor="price">Service Price</label>
            <input type="text" defaultValue={price} {...register("price")} />

            <input type="submit" value="UPDATE" />
          </form>
        </div>
        <div className="col-md-4">
          <div className="text-center">

            <p>Your Current Image</p>
            <img className="preview-img" src={updateImg} alt="" />

            <input onChange={handleUpdateImage} type="file" />

            {/* loading spinner */}
            {
              loadingSpinner && <div class="spinner-border text-warning" role="status"></div>
            }


          </div>
        </div>
      </div>


    </div>
  );
};

export default ServiceUpdateModal;