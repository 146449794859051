const firebaseConfig = {
  // apiKey: "AIzaSyBe7YqMcogHJ3TvdbdaR7xVqnpv5VDaL84",
  // authDomain: "barber-shop-6e914.firebaseapp.com",
  // projectId: "barber-shop-6e914",
  // storageBucket: "barber-shop-6e914.appspot.com",
  // messagingSenderId: "264242322180",
  // appId: "1:264242322180:web:c3fa012db2da547fd50e51"


  apiKey: "AIzaSyBfR4ihO8I-w2iC6h89qAXuSkL1XKwqW8Y",
  authDomain: "agam-transport.firebaseapp.com",
  projectId: "agam-transport",
  storageBucket: "agam-transport.appspot.com",
  messagingSenderId: "859588001254",
  appId: "1:859588001254:web:79578f4a74c8b91abc2a27"
};

export default firebaseConfig;