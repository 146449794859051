import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';


const PUBLIC_KEY = "pk_test_51PUd9D1xHWI4istfe4zGmzQBylgm94pF34eps5GSd4AlKWeIHxe2Or8irHsoOsG19dFLH29Pfyy3i6h1GuXwtwnB00CrlBfnaB";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const StripeContainer = ({handlePaymentProcess}) => {
   
    return (
        <Elements stripe ={stripeTestPromise}>
            <PaymentForm handlePaymentProcess={handlePaymentProcess} />
        </Elements>
    );
};

export default StripeContainer;