import React from 'react';
import './Contact.css';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router';
import contactPic from '../../../images/AgamContact.png';
import tagsPic from '../../../images/Tag.JPG';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';
import { getFirestore, doc, setDoc, serverTimestamp } from "firebase/firestore"; // Import Firestore and necessary functions
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { toast } from 'react-toastify';

const app = initializeApp(firebaseConfig);



const Contact = () => {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        sendContactData(data);
    };

    const history = useHistory();


    const sendContactData = (data) => {
        // calling firebase oncall function named as sendContactMessage
        toast.info("Sending message...", { position: "top-right" });

        const auth = getAuth();

        let UID;

        // if user is not logged in
        if (!auth.currentUser) {
            UID = "N/A";
        }
        else {
            UID = auth.currentUser.uid;
        }

        const ContactData = {
            // current user logged in uid
            uid: UID,
            Name: data.name,
            Email: data.email,
            Phone: data.phone,
            Message: data.message
        }

        const functions = getFunctions(app);
        const sendContactMessage = httpsCallable(functions, 'sendContactMessage');

        sendContactMessage(ContactData).then(async (result) => {

            // if message sent successfully, then show alert
            toast.success("Message Sent successfully!", { position: "top-right" });


            // reset the form
            document.getElementById("contact-form").reset();

            // go to login page
            history.push('/login');

        }
        ).catch((error) => {
            // Getting the Error details.
            toast.error("Error sending message!", { position: "top-right" });
            console.log(error);
        });


    }

    return (
        <section className="container-fluid" id="contact">
            <div className="row align-items-center">
                <div className="col-md-7">
                    <img className="img-fluid ContactImg" src={contactPic} alt="" />

                </div>
                <div className="col-md-5">
                    <div className=" contact-us">
                        <h3>Contact Us</h3>
                        <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                            <input placeholder="Your Name" type="text" {...register("name")} />
                            <input placeholder="Your Email" type="email" {...register("email",)} />
                            <input placeholder="Phone Number" type="number" {...register("phone",)} />
                            <textarea placeholder="Message" {...register("message",)} />
                            <input type="submit" value="SUBMIT" />
                        </form>
                    </div>
                </div>
                {/* tags image */}
                <div className="tags">
                    <img className="img-fluid TagImg" src={tagsPic} alt="" />    
                </div>
            </div>
        </section>
    );
};

export default Contact;