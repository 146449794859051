import React from 'react';
import Footer from '../Shared/Footer/Footer';
import Header from '../Home/Header/Header';
import Services from '../Home/Services/Services';
import NavbarForPages from '../Shared/NavbarForPages/NavbarForPages';

const ServicesPage = () => {
    return (
        < div id="home">
            <NavbarForPages />
            <div style={{ marginTop: '70px' }}></div>
            <Services />
            <Footer />
        </div>
    );
};

export default ServicesPage;