import React, { useContext, useState } from 'react';
import './AddAppointment.css';
import { useForm } from "react-hook-form";
import { UserContext } from '../../../App';
import StripeContainer from '../Payment/StripeContainer';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import firebaseConfig from '../../Login/firebase.config';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import firebase from 'firebase/compat/app';

// Email sb-gi0fq31595042@personal.example.com
// Password 6Pt1nM^P


// firebase functions for firebase
const app = initializeApp(firebaseConfig);


const AddAppointment = () => {

    const { serviceInfo, userInfo } = useContext(UserContext);
    const [selectedService, setSelectedService] = serviceInfo;
    const [loggedInUser, setLoggedInUser] = userInfo;
    const [isDateSelected, setIsDateSelected] = useState(false);
    const [shippingData, setShippingData] = useState(null);
    const history = useHistory();

    const handleDateChange = (event) => {
        console.log(event.target.value);
        const functions = getFunctions(app);
        const checkAppointment = httpsCallable(functions, 'checkAppointment');

        checkAppointment({ uid: loggedInUser.uid, date: event.target.value })
            .then((result) => {
                console.log(result.data);
                if (result.data.status === "success") {
                    setIsDateSelected(event.target.value !== '');
                    // toast.info("Date is available!", { position: "top-right" });
                } else {
                    setIsDateSelected(false);
                    toast.error("Date not available!, please select a different date", { position: "top-right" });
                }
            }
            );
    };


    const { register, handleSubmit, formState: { errors } } = useForm();

    // firebase functions for firebase
    const functions = getFunctions(app);


    //set order data in state
    const onSubmit = data => {
        // remove barber name from data
        delete data.barber;

        console.log(data, selectedService);

        if(selectedService.price === "OnContact"){
            toast.info("Processing Your booking!", { position: "top-right" });
            const makeAppointment = httpsCallable(functions, 'makeAppointment');

            const orderInformation = {
                name: data.name,
                email: loggedInUser.email,
                address: data.address,
                phone: data.phone,
                service: selectedService.service,
                tutor: data.tutor,
                status: "pending",
                price: selectedService.price,
                date: data.date,
                time: "N/A",
                _id: "123456",
                paymentInfo: "On Site",
                uid: loggedInUser.uid

            }

            console.log(orderInformation);

            makeAppointment(orderInformation).then((result) => {
                console.log(result.data);
                if (result.data.status === "success") {
                    toast.success("Booking Successful!", { position: "top-right" });
                    history.push('/home');
                } else {
                    // if payment failed
                    toast.error("Booking Issue!", { position: "top-right" });
                }
            }
            ).catch((error) => {
                // Getting the Error details.
                toast.error("Error sending message!", { position: "top-right" });
                console.log(error);
            }
            );

            return;
        }

        setShippingData(data);
    };


    // call firebase function for payment
    const callPaypal = (data) => {

        console.log("called paymentFirebase");

        const payProduct = httpsCallable(functions, 'payProduct');


        // sample data input

        // {
        //     "name": "Customer Name",
        //     "email": "test@gmail.com",
        //     "address": "Dhaka, Bangladesh",
        //     "phone": "017XXXXXXXX",
        //     "service": "Service Name",
        //     "tutor": "AGAM",
        //     "status": "pending",
        //     "price": "599.00",
        //     "date": "12/12/2021",
        //     "time": "12:00 PM",
        //     "_id": "123456",
        //     "paymentInfo": "123456",
        //     "uid" : "zM7bhzylcdO4xOXQwttOwFWR0Le2"
        // }

        // if(selectedService?.payOnsite === true){

        // }

        const orderInformation = {
            name: shippingData.name,
            email: loggedInUser.email,
            address: shippingData.address,
            phone: shippingData.phone,
            service: selectedService.service,
            tutor: shippingData.tutor,
            status: "pending",
            price: selectedService.price,
            date: shippingData.date,
            time: "N/A",
            _id: "123456",
            paymentInfo: "123456",
            uid: loggedInUser.uid

        }

        console.log(orderInformation);

        toast.info("Payment Processing...", { position: "top-right" });

        payProduct(orderInformation).then((result) => {
            console.log(result.data);
            if (result.data.status === "success") {
                // open result.data.link in same tab
                window.location.href = result.data.link;
                // window.open(result.data.link, "_blank");
            } else {
                // if payment failed
                toast.error("Payment Issue!", { position: "top-right" });
            }
        }
        ).catch((error) => {
            // Getting the Error details.
            toast.error("Error sending message!", { position: "top-right" });
            console.log(error);
        }
        );

    }


    const handlePaymentProcess = (paymentInfo) => {
        const orderInformation = {
            service: selectedService.service,
            price: selectedService.price,
            email: loggedInUser.email,
            status: "pending",
        }
        const allOrderInfo = { ...shippingData, ...orderInformation, ...{ paymentInfo } };

        // fetch('https://stormy-thicket-33100.herokuapp.com/addAppointment', {
        //     method : 'POST',
        //     headers: {'Content-Type' : 'application/json'},
        //     body: JSON.stringify(allOrderInfo)
        // })
        // .then(result =>console.log(result));

        console.log(allOrderInfo);
        alert("Your Order Placed Successfully");


    };


    const options = [
        {
            label: "AGAM",
            value: "AGAM",
        },
    ];



    return (
        <div>
            <div className="row" style={{ display: shippingData ? "none" : "block" }} >
                <div className="add-appointment-section">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <label htmlFor="name">Your Name</label>
                        <input {...register("name", { required: true })} />
                        {errors.name && <span>This field is required</span>}

                        <label htmlFor="phone">Phone</label>
                        <input type="tel" {...register("phone", { required: true })} />
                        {errors.phone && <span>This field is required</span>}

                        <label htmlFor="address">Address</label>
                        <input type="text" {...register("address", { required: true })} />
                        {errors.address && <span>This field is required</span>}

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="date">Select Date</label>
                                <input
                                    type="date"
                                    {...register("date", { required: true })}
                                    onChange={handleDateChange} // Update state on date change
                                    min={new Date().toISOString().split('T')[0]} // Set min attribute to today's date
                                />
                                {errors.date && <span>This field is required</span>}
                            </div>
                            {/* <div className="col-md-4">
                                <label htmlFor="time">Select Time</label>
                                <select {...register("time", { required: true })}>
                                    <option value="10.00-11.00am" >10.00-11.00 AM</option>
                                    <option value="11.30-12.30am" >11.30-12.30 AM</option>
                                    <option value="1.00-2.00pm" >1.00-2.00 PM</option>
                                    <option value="4.00-5.00pm" >4.00-5.00 PM</option>
                                    <option value="5.30-6.30pm" >5.30-6.30 PM</option>
                                    <option value="7.00-8.00pm" >7.00-8.30 PM</option>
                                </select>
                                {errors.time && <span>This field is required</span>}

                            </div> */}
                            <div className="col-md-4">
                                <label htmlFor="tutor">Select Instructor</label>
                                <select {...register("tutor", { required: true })}>
                                    {options.map((option, index) => (
                                        <option value={option.value} key={index}>{option.label}</option>
                                    ))}
                                </select>
                                {errors.tutor && <span>This field is required</span>}
                            </div>
                        </div>

                        {isDateSelected && (
                            <input
                                type="submit"
                                value="Process To Payment"
                            />
                        )}

                    </form>
                    <div className="services-section">
                        {
                            selectedService !== null ?
                                <div>
                                    <p>Selected Service :</p>
                                    <h5> Service: {selectedService.service}</h5>
                                    <h5>Price: £{selectedService.price}</h5>
                                </div>
                                :
                                <div onClick={() => {
                                    history.push('/services');
                                }}>
                                    <h5 style={{ cursor: "pointer" }}>Plese Select A Service First</h5>
                                </div>
                        }
                    </div>

                </div>
            </div>

            {/* Payment System */}

            {/* <div className="row payment-section" style={{ display: shippingData ? "block" : "none" }}>
                <h5 className="mb-3">Card Information</h5>
                <StripeContainer handlePaymentProcess={handlePaymentProcess} />
            </div> */}
            <div className='' style={{ display: shippingData ? "block" : "none" }}>
                <h5 className="mb-3">Payment Information</h5>
                {/* btn for payment */}
                <div class="payPal" onClick={callPaypal}>

                    <div class="responsivePayPal">
                        <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png" alt="Check out with PayPal" style={{ maxWidth: "100%" }} />
                    </div>

                </div>
                
            </div>

        </div>
    );
};

export default AddAppointment;