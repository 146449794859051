import React from 'react';
import './Footer.css';
import logo from '../../../images/MainLogo.png';
import { Link } from 'react-router-dom';

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <img className="footer-logo" src={logo} alt="" />
                        <h6>Address:</h6>
                        <p>Email:Info@agamtransporttraining.co.uk  <br />
                        Unit 3, Charnwood Business Park, Bilston WV14 0QJ</p>
                        <h6>Phone:</h6>
                        <p>07450294402</p>
                        <h6>Social Link:</h6>
                        <div className="footer-social-link">
                            <Link to="//www.facebook.com/" target="_blank"> <i className="fab fa-facebook-f"></i></Link>
                            <Link to="//www.twitter.com/" target="_blank"><i className="fab fa-twitter"></i></Link>
                        </div>

                    </div>
                    <div className="col-md-2">
                        <h5 className='quick-heading' >Quick Link :</h5>
                        <ul className="quick-link">
                            <li>
                                <Link to="/home">Home</Link>
                            </li>
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            <li>
                                <Link to="/services">Services</Link>
                            </li>
                            <li>
                                <Link to="/reviews">Reviews</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5>Terms and Legal</h5>
                        <ul className="quick-link">
                            <li>Terms Of Usage</li>
                            <li>Copyrights</li>
                            <li>
                                <Link to="/login">Customer Login</Link>
                            </li>
                            <li>Cookie Policy</li>
                            <li>How to Contact</li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h5>Working Hours :</h5>
                        <p>Monday - Sunday : 08:00 am till 17:00 pm</p>
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default Footer;