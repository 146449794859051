import React, { useState } from 'react';
import AddService from '../AddService/AddService';
import AllAppointments from '../AllAppointments/AllAppointments';
import AllServices from '../ManageServices/ManageServices';
import MakeAdmin from '../MakeAdmin/MakeAdmin';
import SideNavbar from '../SideNavber/SideNavbar';
import './Dashboard.css';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getAuth, signOut } from "firebase/auth";
import { useContext } from 'react';
import { UserContext } from '../../../App';

const Dashboard = () => {

    const [activeNav, setActiveNav] = useState("all appointments");

    // const { userInfo } = useContext(UserContext);
    // const [loggedInUser, setLoggedInUser] = userInfo;
    // const history = useHistory();

    // const allowedUsers = ["danishzulfiqar100@gmail.com", "amarindergill84@gmail.com", "talhaiqbal7t7@gmail.com"]

    // if (loggedInUser.email && !allowedUsers.includes(loggedInUser.email)) {
    //     toast.error("You are not authorized to access this page");
    //     signOut(getAuth());
    //     setLoggedInUser({});
    //     sessionStorage.removeItem('token');
    //     history.push('/login');
    // }


    return (
        <div className="container-fluid dashboard">
            <div className='row'>
                <div className="col-md-2 text-center">
                    <SideNavbar activeNav ={activeNav}  setActiveNav={setActiveNav} />
                </div>
                <div className="col-md-10 p-5 bg-light ">
                    <div className="dashboard-container">
                        {
                            activeNav === "all appointments" && <AllAppointments />
                        } 
                        {
                            activeNav === "manage services" && <AllServices />
                        }   
                        {
                            activeNav === "add new service" && <AddService />
                        }
                        {/* {
                            activeNav === "make admin" && <MakeAdmin />
                        } */}

                    </div>
                   
                </div>            
            </div>
        </div>
    );
};

export default Dashboard;