import React, { useEffect, useState } from 'react';
import './Services.css';
import ServiceCard from '../ServiceCard/ServiceCard';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionTitle from '../../Shared/SectionTitle/SectionTitle';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';

const app = initializeApp(firebaseConfig);

//  Fake array for Service cards

// const FakeServices = [
//     {
//         _id: '1',

//         service: 'Transport Manager / Responsible person Assistance',

//         price: 50,

//         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/_MG_4485.JPG/:/cr=t:0%25,l:1.75%25,w:96.5%25,h:100%25/rs=w:776,h:776,cg:true'

//     },
//     {
//         _id: '2',

//         service: 'Public Inquiry Support and Preparation',

//         price: 300,

//         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/Montage%20TAC-23fcde1.jpg/:/cr=t:0%25,l:1.65%25,w:96.7%25,h:100%25/rs=w:776,h:776,cg:true'

//     },
//     {
//         _id: '3',

//         service: 'Policies, Procedures & Documentation',

//         price: 120,

//         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/AdobeStock_150191362.jpeg/:/cr=t:0%25,l:16.66%25,w:66.68%25,h:100%25/rs=w:776,h:776,cg:true'

//     },
//     {
//         _id: '4',

//         service: 'Operator Licence Applications & Amendments',

//         price: 100,

//         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/sta%20int%20o%20loc%20-%20Copy.jpg/:/cr=t:0%25,l:27.16%25,w:45.69%25,h:100%25/rs=w:776,h:776,cg:true'

//     },
//     {
//         _id: '5',

//         service: 'Driver Exit Checks',

//         price: 100,

//         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/Daily%20Checks%20Wheels%20.jpeg/:/cr=t:0%25,l:16.68%25,w:66.64%25,h:100%25/rs=w:776,h:776,cg:true'

//     },
//     {   
//         _id: '6',

//         service: 'Fleet Inspections',

//         price: 120,

//         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/DVSA%20HGV%20Inspection%20Manual.JPG/:/cr=t:14.52%25,l:0%25,w:100%25,h:70.95%25/rs=w:388,h:388,cg:true'

//     },

// ];

const Services = () => {

    const [services, setServices] = useState([]);

    useEffect(() => {
        const db = getFirestore(app);
        // path to services collection

        // data/agam_data/services
        const servicesCol = collection(db, 'data/agam_data/services');
        const getServices = async () => {
            const servicesSnapshot = await getDocs(servicesCol);
            const servicesList = servicesSnapshot.docs.map(doc => doc.data());
            
            // Sorting services by name 
            // Sort by id in ascending order
            servicesList.sort((a, b) => a.id - b.id);
            
            // Showing last 4 services
            let lastFour = servicesList.slice(-4);

            setServices(lastFour);
        }
        getServices();
        
    }, []);


    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);


    return (
        <section className="container" id="services">

            <SectionTitle subTitle={"AGAM"} title={"Fleet Operator Recognition Scheme (FORS)"} />
            <div data-aos="fade-up" className="row">
                {
                    services.map((service, index) => <ServiceCard service={service} key={index}></ServiceCard>)
                }
            </div>

        </section>
    );
};

export default Services;