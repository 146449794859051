import React from 'react';
import { getFirestore, collection, getDocs, where, query, updateDoc } from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';
// toasts
import { toast } from 'react-toastify';

const app = initializeApp(firebaseConfig);

const AppointmentList = ({ appointment, index }) => {
    const { name, email, address, phone, service, tutor, status, price, date, time, _id } = appointment;
    const payment_id = appointment.paymentInfo;
    const createAt = appointment?.createdAt;
    const id = appointment.id;

    const handleStatus = (e, id) => {
        console.log(e.target.value, id);
        const updateStatus = {
            status: e.target.value
        }

        // firestore data for allAppointments
        const db = getFirestore(app);
        // update the status of the appointment with field id = id
        const q = query(collection(db, '/appointments/allAppointments/apt_list'), where('id', '==', id));

        getDocs(q)
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    // update the status of the appointment with field id = id

                    // using key value pair to update the status
                    const docRef = doc.ref;
                    
                    updateDoc(docRef, 
                        "status", e.target.value
                    ).then(() => {
                        console.log("Document successfully updated!");
                        toast.success("Status updated successfully!", { position: "top-right" });
                    }
                    ).catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                        toast.error("Error updating status!", { position: "top-right" });
                    });
                    
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

    }




    return (
        <tr>
            <td>{1 + index}</td>
            <td>
                <h6>{name}</h6>
                <p>Email: {email}</p>
                <p>Phone: {phone}</p>
                {/* <p>{address}</p> */}
                <p>Booked on : {createAt}</p>
            </td>
            <td>
                <h6>{service}</h6>
                <p>tutor:<b> {tutor}</b></p>
                <p>£ {price}</p>
                <p>Apt-Date : {date}</p>
                {/* <p>Time : {time}</p> */}
                <p>Payment Id  :  {payment_id} </p>
            </td>
            <td>

                <select onChange={(e) => handleStatus(e, id)}>
                    <option defaultValue={status} selected>{status}</option>
                    {status !== "pending" && <option value="pending">pending</option>}
                    {status !== "done" && <option value="done">done</option>}
                </select>
            </td>
        </tr>
    );
};

export default AppointmentList;