import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../App';
import MyAppointmentCard from '../MyAppointmentCard/MyAppointmentCard';
// firestore data
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';


const MyAppointments = () => {
    const {serviceInfo, userInfo} = useContext(UserContext);
    const [loggedInUser, setLoggedInUser] = userInfo;


    const [myAppointments, setMyAppointments] = useState([]);

    // Fake Data for MyAppointmentCard
    // const fakeData = [
    //     {
    //         service: 'Service Name',
    //         date: '12/12/2021',
    //         time: '12:00 PM',
    //         tutor: 'Barber Name',
    //         status: 'On Going'
    //     },
    // ];

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);

        const q = query(collection(db, "appointments/allAppointments/apt_list"), where("uid", "==", loggedInUser.uid));
        getDocs(q).then((querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
            setMyAppointments(data);
        });
    }
        , []);
    


    return (
        <div className="row">
            {
                myAppointments.map((appointment, index) =><MyAppointmentCard appointment={appointment} key={index} />)
            }
            
        </div>
    );
};

export default MyAppointments;