import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './components/Home/Home/Home';
import AboutPage from './components/AboutPage/AboutPage';
import ServicesPage from './components/ServicesPage/ServicesPage';
import FORSPage from './components/FORSPage/FORSPage';
import ReviewsPage from './components/ReviewsPage/ReviewsPage';
import ContactPage from './components/ContactPage/ContactPage';
import Dashboard from './components/AdminDashboard/Dashboard/Dashboard';
import UserDashboard from './components/UserDashboard/UserDashboard/UserDashboard';
import { createContext } from 'react';
import Login from './components/Login/Login';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import NotFound from './components/NotFound/NotFound';
import PaymentSuccessful from './components/PaymentSuccess/Success';
import PaymentCancel from './components/PaymentCancel/Cancel';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const UserContext = createContext();


function App() {

  const [loggedInUser, setLoggedInUser] = useState({})
  const [selectedService, setSelectedService] = useState(null);



  return (
    <UserContext.Provider value={{
      serviceInfo: [selectedService, setSelectedService],
      userInfo: [loggedInUser, setLoggedInUser],

    }}>

      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <Router>
        <Switch>

          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <PrivateRoute path="/admin">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/userDashboard">
            <UserDashboard />
          </PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/services">
            <ServicesPage />
          </Route>
          <Route path="/fors">
            <FORSPage />
          </Route>
          <Route path="/reviews">
            <ReviewsPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/paymentSuccess">
            <PaymentSuccessful />
          </Route>
          <Route path="/paymentCancel">
            <PaymentCancel />
          </Route>
          <Route exact path="*">
            <NotFound />
          </Route>


        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
