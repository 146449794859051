import React, { useState } from 'react';
import './AddService.css';
import { useForm } from "react-hook-form";
// firestor and firebase
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';
import { getFirestore, doc, setDoc, serverTimestamp, collection } from "firebase/firestore"; // Import Firestore and necessary functions
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from 'react-toastify';


const app = initializeApp(firebaseConfig);

const AddService = () => {
    const [spinner, setSpinner]= useState(false);
    const [imgUrl, setImgUrl] = useState(null);

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        const addService = {
            id : Math.floor(Math.random() * 100000),
            service: data.service,
            price: data.price,
            img: imgUrl,
        };
    
        const db = getFirestore();
        
        const docRef = doc(collection(db, "data/agam_data/services"));
    
        await setDoc(docRef, {
            ...addService,
            createdAt: serverTimestamp(),
        }).then(() => {
            toast.success("Service added successfully!", { position: "top-right" });
            reset();
        }).catch(
            (error) => {
                toast.error("Failed to add service!", { position: "top-right" });
            }
        );
    };




    // uploading image to imgbb and get url link
    const  handleImageUpload = async event =>{
        setSpinner(true);
        const imageData = new FormData();
        imageData.set('key', '5db3a5ec8d1a5a41ae46727cd85094e2');
        imageData.append('image', event.target.files[0] )

        const response = await fetch('https://api.imgbb.com/1/upload', {
          method: 'POST',
          body: imageData
        }).catch((error) => {
            console.error('Error:', error);
            toast.error("Failed to upload image!", { position: "top-right" });
        });
        const data = await response.json();
        setImgUrl(data.data.display_url);
        setSpinner(false);
     
    }



    return (
        <div className="add-service-container">
            <h2 className="text-center mb-5">ADD NEW SERVICE</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
          
                <div className="row">
                    <div>
                        <label htmlFor="service">Service Name</label>
                        <input {...register("service")} />

                        <label htmlFor="price">Service Price</label>
                        <input type="text" {...register("price")} />

                        

                        <label htmlFor="service-img">Add Service Picture</label>
                        <input name="service-img" type="file" onChange={handleImageUpload}/>
                    </div>
                    
                </div>
      
                {/* condition submit button */}
                {imgUrl === null ? <input type="submit" disabled="disabled" style={{backgroundColor:"#f7f7f7", color:"#BDC3C7" }} /> : <input type="submit"/>}
            
            </form>
            {
                spinner &&  <div class="spinner-border text-warning spinner-position" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
            }
        </div>
    );
};

export default AddService;