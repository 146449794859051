import React, { useEffect, useState } from 'react';
import './Reviews.css';
import ReviewCard from '../ReviewCard/ReviewCard';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionTitle from '../../Shared/SectionTitle/SectionTitle';
// firebase firestore
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const app = initializeApp(firebaseConfig);

const Reviews = () => {

    // Fake array for Review cards
    // const FakeReviews = [
    //     {
    //         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, quas.",
    //         img: 'https://i.ibb.co/7KjvRtq/1.jpg',
    //         name: 'John Doe',
    //         designation: 'CEO, ABC Company'
    //     },
    //     {
    //         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, quas.",
    //         img: 'https://i.ibb.co/7KjvRtq/1.jpg',
    //         name: 'John Doe',
    //         designation: 'CEO, ABC Company'
    //     },
    //     {
    //         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, quas.",
    //         img: 'https://i.ibb.co/7KjvRtq/1.jpg',
    //         name: 'John Doe',
    //         designation: 'CEO, ABC Company'
    //     },
    // ]

    const [allReviews, setAllreviews] = useState([]);

    useEffect(() => {
        // Fetching data from 
        const db = getFirestore(app);
        const reviewsCollection = collection(db, 'data/agam_data/reviews');
        const getReviews = async () => {
            const reviewsSnapshot = await getDocs(reviewsCollection);
            const reviewsList = reviewsSnapshot.docs.map(doc => doc.data());
            return reviewsList;
        }
        getReviews().then(reviews => setAllreviews(reviews));
        
    }, [])


    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <section className="container" id="reviews">

            <SectionTitle subTitle={"WHAT PEOPLE SAY ABOUT US"} title={"CLIENT REVIEWS"} />

            <div data-aos="fade-left" className="row">
                {
                    allReviews.map((review, index) => <ReviewCard review={review} key={index}> </ReviewCard>)
                }
            </div>

        </section>
    );
};

export default Reviews;