import React from 'react';
import Footer from '../Shared/Footer/Footer';
import Header from '../Home/Header/Header';
import Contact from '../Home/Contact/Contact';
import NavbarForPages from '../Shared/NavbarForPages/NavbarForPages';

const ContactPage = () => {
    return (
        < div id="home">
            <NavbarForPages />
            <div style={{ marginTop: '70px' }}></div>
            <Contact />
            <Footer />
        </div>
    );
};

export default ContactPage;