import React, { useEffect, useState, useRef } from 'react';
import AllServicesList from '../AllServicesList/AllServicesList';
import ServiceUpdateModal from '../ServiceUpdateModal/ServiceUpdateModal';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../Login/firebase.config';
import { deleteDoc, query, where, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';


const app = initializeApp(firebaseConfig);



const ManageServices = () => {
    const [services, setServices] = useState([]);
    const [loadService, setLoadService] = useState({});
    const [loadModal, setLoadModal] = useState(false);

    console.log(loadService);

    // const FakeServices = [
    //     {
    //         _id: '1',

    //         service: 'Transport Manager / Responsible person Assistance',

    //         price: 50,

    //         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/_MG_4485.JPG/:/cr=t:0%25,l:1.75%25,w:96.5%25,h:100%25/rs=w:776,h:776,cg:true'

    //     },
    //     {
    //         _id: '2',

    //         service: 'Public Inquiry Support and Preparation',

    //         price: 300,

    //         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/Montage%20TAC-23fcde1.jpg/:/cr=t:0%25,l:1.65%25,w:96.7%25,h:100%25/rs=w:776,h:776,cg:true'

    //     },
    //     {
    //         _id: '3',

    //         service: 'Policies, Procedures & Documentation',

    //         price: 120,

    //         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/AdobeStock_150191362.jpeg/:/cr=t:0%25,l:16.66%25,w:66.68%25,h:100%25/rs=w:776,h:776,cg:true'

    //     },
    //     {
    //         _id: '4',

    //         service: 'Operator Licence Applications & Amendments',

    //         price: 100,

    //         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/sta%20int%20o%20loc%20-%20Copy.jpg/:/cr=t:0%25,l:27.16%25,w:45.69%25,h:100%25/rs=w:776,h:776,cg:true'

    //     },
    //     {
    //         _id: '5',

    //         service: 'Driver Exit Checks',

    //         price: 100,

    //         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/Daily%20Checks%20Wheels%20.jpeg/:/cr=t:0%25,l:16.68%25,w:66.64%25,h:100%25/rs=w:776,h:776,cg:true'

    //     },
    //     {
    //         _id: '6',

    //         service: 'Fleet Inspections',

    //         price: 120,

    //         img: 'https://img1.wsimg.com/isteam/ip/df11d4f8-df9b-4078-8276-6586a007e157/DVSA%20HGV%20Inspection%20Manual.JPG/:/cr=t:14.52%25,l:0%25,w:100%25,h:70.95%25/rs=w:388,h:388,cg:true'

    //     },

    // ];


    const getServices = async () => {
        const db = getFirestore(app);
        // path to services collection

        // data/agam_data/services
        const servicesCol = collection(db, 'data/agam_data/services');
        const servicesSnapshot = await getDocs(servicesCol);
        const servicesList = servicesSnapshot.docs.map(doc => doc.data());
        setServices(servicesList);
    }

    useEffect(() => {
        getServices();
    }, []);



    //Load Single Service from Database to Edit
    const handleEditService = (id) => {
        const data = services.find(service => service.id === id);

        setLoadService(data);
        setLoadModal(true);

    };


    const handleDeleteService = (id) => {
        const db = getFirestore();
        const q = query(collection(db, "data/agam_data/services"), where("id", "==", id));

        // delete document

        getDocs(q).then((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    // deleting document
                    deleteDoc(doc.ref).then(() => {
                    toast.info("Service deleted successfully!", { position: "top-right" });
                    }).catch(error => console.error("Error deleting document: ", error));
                });

            } else {
                console.log("No such document!");
            }
        }
        ).catch((error) => {
            console.log("Error getting documents:", error);
        });

        getServices();

    }



    return (
        <div>
            <table className="table" id='servicesTable'>
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Service</th>
                        <th scope="col">Price</th>
                        <th scope="col">Picture</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        services.map((service, index) => <AllServicesList
                            service={service} key={index} index={index}
                            handleEditService={handleEditService}
                            handleDeleteService={handleDeleteService}
                        />)
                    }
                </tbody>
            </table>

            {
                loadModal && <ServiceUpdateModal setLoadModal={setLoadModal} loadService={loadService} />
            }


        </div>




    );
};

export default ManageServices;