import React from 'react';
import './About.css';
import abougBg from '../../../images/NewAboutAgam.jpeg';



const About = () => {
    return (
        <section className="about-section" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h5>WELCOME TO THE AGAM</h5>
                        <h3>WE OFFER COMPLIANCE AND TRAINING</h3>
                        <p className="mt-4">
                            Amarinder Gill, the managing director of AGAM Transport is also a member of IOTA (Institute of Transport Administration) <a href='https://www.iota.org.uk/'>https://www.iota.org.uk </a>. IOTA is a highly pristigeous organisation, educating transport management since 1944. Amarinder has an extensive experience in the Road Haulage industry, having worked for many years as a transport manager, trainer, and consultant for both road haulage and passenger transport operators. Throughout his career, he has earned numerous qualifications that enhance his expertise and knowledge in the transport sector.
                        </p>
                    </div>
                    <div className="col-md-5 d-flex justify-content-end">
                        <img className="img-fluid moc-up " src={abougBg} alt="pic" />


                    </div>

                    <div className="col-md-3 about-middle align-self-start">
                        <h3>Making Transport Compliance & Training Easy and Roads Safer</h3>
                        <p className="mt-4">
                            Amarinder Gill is highly respected by his peers for his hands-on and innovative approach to compliance and training in the transport industry. With extensive experience in the field, he has played a key role in teaching future transport managers and ensuring compliance across various organizations. His dedication to the industry and his ability to implement effective training programs have made him a trusted and influential figure in the transport sector.                        </p>
                    </div>
                </div>
            </div>


        </section>
    );
};

export default About;